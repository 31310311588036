import React, { Component } from "react";
import SignupUi from "./signup-ui";

class Signup extends Component {
    render() {
        return (
            <React.Fragment>
                <SignupUi/>
            </React.Fragment>
        )
    }
}

export default Signup;
import "./App.css";
import videoBg from "./videoBg.mp4";
import logo from "./logo.svg";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div className="NotFound">
      <h1 className="NotFound-h"> 404 Not Found </h1>
      <Link className="NotFound-a" to="/">
        {" "}
        <h5 className="NotFound-a"> Home </h5>{" "}
      </Link>
    </div>
  );
}

export default NotFound;

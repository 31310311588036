import React, { Component } from "react";
import LoginUi from "./login-ui";
class Login extends Component {
    render() {
        return (
            <React.Fragment>
                <LoginUi/>
            </React.Fragment>
        )
    }
}

export default Login;